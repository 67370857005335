<template>
    <div style="height:150px;">
        <a-upload :file-list="fileList" :remove="handleRemove" :before-upload="beforeUpload" accept=".xlsx">
            <a-button>
                <a-icon type="upload"/>
                {{l('SelectFile')}}
            </a-button>
        </a-upload>
        <br>
        <a-button
                type="primary"
                :disabled="fileList.length === 0"
                :loading="uploading"
                style="margin-top: 16px"
                @click="handleUpload"
        >
            {{ uploading ? l('Importing...') : l('import') }}
        </a-button>
    </div>
</template>
<script>

    import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
    import {AppConsts} from "@/abpPro/AppConsts";
    import axios from 'axios';
    import {KanBanServiceProxy} from "../../../../shared/service-proxies";
    import {fileDownloadService} from "../../../../shared/utils";
	import XLSX from 'xlsx';
	import XLSXS from 'xlsx-style';
	import FileSaver from "file-saver";

    let _this;
    export default {
        name: 'importExcelComponent',
        mixins: [ModalComponentBase],
        components: {
            axios
        },
        created() {
            _this = this;
			this.fullData(); // 模态框必须,填充数据到data字段
			this.KanBanServiceProxy = new KanBanServiceProxy(this.$apiUrl, this.$api);
        },
		mounted() {
		    this.getData()
		},
        data() {
            return {
                fileList: [],
                uploading: false,
				data: [],
				entity: {},
				paramDesc: [],
				valueList: []
            }
        },
        methods: {
			getData() {
			    this.isLoading = true;
			    this.KanBanServiceProxy.getForEdit(
			        this.id
			    ).finally(() => {
			        this.isLoading = false;
			    }).then(res => {
			        this.entity = res;
					if(this.entity.value) {
						this.valueList = JSON.parse(this.entity.value);
					}
					if(this.entity.paramDesc) {
						this.paramDesc = JSON.parse(this.entity.paramDesc);
					}
			    })
			},
            handleRemove(file) {
                //移除打开的Excel文件
                const index = this.fileList.indexOf(file);
                const newFileList = this.fileList.slice();
                newFileList.splice(index, 1);
                this.fileList = newFileList;
            },
            beforeUpload(file) {
				let reader = new FileReader();
				let rABS = typeof FileReader !== "undefined" && (FileReader.prototype || {}).readAsBinaryString;
				if(rABS) {
					reader.readAsBinaryString(file);
				}else {
					reader.readAsArrayBuffer(file);
				}
				reader.onload = function (e) {
					let data = e.target.result
					if (!rABS) {
						data = new Uint8Array(data)
					}
					let workBook = XLSX.read(data, {type: rABS ? 'binary' : 'array'})
					workBook.SheetNames.forEach(name => {
						let sheet = workBook.Sheets[name]
						let json = XLSX.utils.sheet_to_json(sheet, {
							raw: true,
							header: 1,
							defval: null
						});
						console.log(json)
						let variableList = [];
						_this.paramDesc.forEach(variable => {
							variableList.push(variable.text)
						})
						if(variableList.toString() === json[0].toString()) {
							let tableData = [];
							json.map((itemData, indexData) => {
								if (indexData > 0) {
								var attr = {};
								_this.paramDesc.map((item, index) => {
									attr[item.paramName] = itemData[index];
								});
								tableData.push(attr);
								}
							});
							console.log(tableData)
							console.log(_this.valueList)
							let Json = tableData.concat(_this.valueList);
							let newJson = [];
							console.log(Json)
							for(let item of Json){
								let flag = true;
								for(let items of newJson){
									if(item.id === items.id){
										flag = false;
									}
								}
								if(flag){
									newJson.push(item);
								}
							}
							_this.data = newJson.sort(function(a, b) {
							  return a.id - b.id
							});
							_this.fileList = [..._this.fileList, file];
							return false;
						}else {
							abp.message.error('表格格式错误！');
						}
					})
				}
            },
            handleUpload() {
				this.uploading = true;
				this.entity.value = JSON.stringify(this.data);
				this.KanBanServiceProxy.createOrUpdate(
				    this.entity
				).finally(() => {
					
				}).then(res => {
					this.uploading = false;
				    this.success(true);
				})
            }
        }
    }
</script>
<style scoped>
</style>
