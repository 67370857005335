<template>
    <div>
        <!-- 标题 -->
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px" />
                <span>{{entity.name + l('Settings')}}</span>
            </div>
        </div>
        <a-config-provider :locale="zh_CN">
			<a-card>
				<a-col :span="18">
					<a-row style="margin-bottom: 10px;">
					    <a-col :span="10">
					        <a-button type="primary" @click="handleAdd()">
					            <a-icon type="plus"/>
					            {{l('Create')}}
					        </a-button>
					    </a-col>
					</a-row>
					<a-spin :spinning="isLoading">
						<a-table :columns="columns"
								 :data-source="tableData"
								 :rowKey="(record)=>record.id"
								 :pagination="false"
								 :scroll="{x: 690,y:500}"
								 size="small"
						>
							<template v-for="(item,index) in parameterList" :slot="item.dataIndex" slot-scope="text, record, index">
								<div>
									<a-input v-if="record.editable" style="margin: -5px 0" :value="text" @change="e => handleChange(e.target.value, record, item.dataIndex)"/>
									<span v-else>
										{{text}}
									</span>
								</div>
							</template>
							<span slot="action" slot-scope="text,record">
								<span v-if="record.editable">
									<a @click="() => saveEditable(record)">{{l('Save')}}</a>
									<a-popconfirm :title="l('Whether_to_cancel_editing?')" @confirm="() => cancel(record)" style="margin-left: 10px;">
										<a>{{l('Cancel')}}</a>
									</a-popconfirm>
								</span>
								<a @click.stop="edit(record)" v-else>{{l('Edits')}}</a>
								<a @click.stop="onDelete(record)" v-if="!record.editable" style="margin-left: 10px;color: red">{{l('Delete')}}</a>
							</span>
						</a-table>
					</a-spin>
				</a-col>
				<a-col :span="5" style="margin-left: 20px;">
					<h3 style="margin-top: 45px;">{{l('Parameter_Description')}}</h3>
					<div style="width: 100%" v-for="(item,index) in parameter">
						<div>{{l('Name') + "：" + item.text}}</div>
						<div>{{l('Parameters') + "：" + item.paramName}}</div>
						<div style="margin-bottom: 10px;">{{l('Remark') + "：" + item.remark}}</div>
					</div>
				</a-col>
			</a-card>
        </a-config-provider>
        <!-- 按钮 -->
        <div class="modal-footer" v-if="!isLook">
            <a-button @click="close">
                {{l('Cancel')}}
            </a-button>
            <a-button :type="'primary'" @click="save">
                {{l('Save')}}
            </a-button>
        </div>
    </div>
</template>

<script>
	import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
	import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
	import { KanBanServiceProxy } from '../../../../shared/service-proxies';
	
	let _this;
	export default {
		name: 'Data-settings',
		mixins: [ModalComponentBase],
		components: {
		    
		},
		data() {
		    return {
		        zh_CN,
		        isEdit: false,
		        isLook: false,
		        id: undefined,
		        isLoading: false,//加载中
				entity: {},
				tableData: [],
				columns: [],
				parameterList: [],
				editingId: '',
				cacheData: [],
				parameter: []
		    }
		},
		created() {
		    _this = this;
		    this.fullData(); // 模态框必须,填充数据到data字段
			this.KanBanServiceProxy = new KanBanServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
		    this.getData()
		},
		methods: {
		    save() {
				this.entity.value = JSON.stringify(this.tableData);
				this.entity.paramDesc = JSON.stringify(this.entity.paramDesc);
				this.KanBanServiceProxy.createOrUpdate(
				    this.entity
				).finally(() => {
					
				}).then(res => {
				    this.success(true);
				})
		    },
			handleAdd() {
				let { tableData } = this;
				let newData = {};
				for(let i in this.parameter) {
					if(this.parameter[i].paramName === 'id') {
						newData[this.parameter[i].paramName] = this.tableData[this.tableData.length - 1].id + 1;
					}else {
						newData[this.parameter[i].paramName] = undefined;
					}
				}
				this.tableData.push(newData);
				// this.tableData = [...tableData, newData];
			},
			onDelete(value) {
				this.$confirm({
					title: this.l("ConfirmInformation"),
					content: this.l("the_selected_option"),
					cancelText: this.l('Cancel'),
					okText: this.l('Ok'),
					onOk: () => {
						let tableData = [...this.tableData];
						let name;
						let target;
						if(this.entity.code == "Departmental-Plan-Completion-Rate" || this.entity.code == "Departmental-Issue-Closure-Rate" || this.entity.code == "Departmental-Compliance-Rate") {
							name = value.departmentName;
							this.tableData = tableData.filter(item => item.departmentName !== name);
						}
						if(this.entity.code == "Plan-Completion-Rate" || this.entity.code == "Issue-Closure-Rate" || this.entity.code == "Compliance-Rate") {
							name = value.month;
							this.tableData = tableData.filter(item => item.month !== name);
						}
						if(this.entity.code == "Audit-Plan" || this.entity.code == "List-of-issues") {
							name = value.id;
							this.tableData = tableData.filter(item => item.id !== name);
						}
						if(this.entity.code == "Level") {
							name = value.levelName;
							this.tableData = tableData.filter(item => item.levelName !== name);
						}
						if(this.entity.code == "Issue-Area") {
							name = value.areaName;
							this.tableData = tableData.filter(item => item.areaName !== name);
						}
						if(this.entity.code == "Issue-Type") {
							name = value.issueType;
							this.tableData = tableData.filter(item => item.issueType !== name);
						}
						if(this.entity.code == "Departmental-hidden-dangers" || this.entity.code == "Key-positions" || this.entity.code == "Hazard-closure-rate" || this.entity.code == "Year-hazard-closure-rate") {
							name = value.id;
							this.tableData = tableData.filter(item => item.id !== name);
						}
					}
				})
			},
			edit(value) {
				let newData = [...this.tableData];
				let newCacheData = [...this.cacheData];
				let name;
				let target;
				let targetCache;
				if(this.entity.code == "Departmental-Plan-Completion-Rate" || this.entity.code == "Departmental-Issue-Closure-Rate" || this.entity.code == "Departmental-Compliance-Rate") {
					name = value.departmentName;
					target = newData.find(item => name === item.departmentName);
					targetCache = newCacheData.find(item => name === item.departmentName);
				}
				if(this.entity.code == "Plan-Completion-Rate" || this.entity.code == "Issue-Closure-Rate" || this.entity.code == "Compliance-Rate") {
					name = value.month;
					target = newData.find(item => name === item.month);
					targetCache = newCacheData.find(item => name === item.month);
				}
				if(this.entity.code == "Audit-Plan" || this.entity.code == "List-of-issues") {
					name = value.id;
					target = newData.find(item => name === item.id);
					targetCache = newCacheData.find(item => name === item.id);
				}
				if(this.entity.code == "Level") {
					name = value.levelName;
					target = newData.find(item => name === item.levelName);
					targetCache = newCacheData.find(item => name === item.levelName);
				}
				if(this.entity.code == "Issue-Area") {
					name = value.areaName;
					target = newData.find(item => name === item.areaName);
					targetCache = newCacheData.find(item => name === item.areaName);
				}
				if(this.entity.code == "Issue-Type") {
					name = value.issueType;
					target = newData.find(item => name === item.issueType);
					targetCache = newCacheData.find(item => name === item.issueType);
				}
				if(this.entity.code == "Departmental-hidden-dangers" || this.entity.code == "Key-positions" || this.entity.code == "Hazard-closure-rate" || this.entity.code == "Year-hazard-closure-rate") {
					name = value.id;
					target = newData.find(item => name === item.id);
					targetCache = newCacheData.find(item => name === item.id);
				}
				this.editingId = name;
				if (target) {
					target.editable = true;
					this.tableData = newData;
					Object.assign(targetCache, target);
					this.cacheData = newCacheData;
				}
			},
			saveEditable(value) {
				let newData = [...this.tableData];
				let name;
				let target;
				if(this.entity.code == "Departmental-Plan-Completion-Rate" || this.entity.code == "Departmental-Issue-Closure-Rate" || this.entity.code == "Departmental-Compliance-Rate") {
					name = value.departmentName;
					target = newData.find(item => name === item.departmentName);
				}
				if(this.entity.code == "Plan-Completion-Rate" || this.entity.code == "Issue-Closure-Rate" || this.entity.code == "Compliance-Rate") {
					name = value.month;
					target = newData.find(item => name === item.month);
				}
				if(this.entity.code == "Audit-Plan" || this.entity.code == "List-of-issues") {
					name = value.id;
					target = newData.find(item => name === item.id);
				}
				if(this.entity.code == "Level") {
					name = value.levelName;
					target = newData.find(item => name === item.levelName);
				}
				if(this.entity.code == "Issue-Area") {
					name = value.areaName;
					target = newData.find(item => name === item.areaName);
				}
				if(this.entity.code == "Issue-Type") {
					name = value.issueType;
					target = newData.find(item => name === item.issueType);
				}
				if(this.entity.code == "Departmental-hidden-dangers" || this.entity.code == "Key-positions" || this.entity.code == "Hazard-closure-rate" || this.entity.code == "Year-hazard-closure-rate") {
					name = value.id;
					target = newData.find(item => name === item.id);
				}
				if (target) {
					delete target.editable;
					this.tableData = newData;
				}
				this.editingKey = '';
			},
			cancel(value) {
				let newData = [...this.tableData];
				let name;
				let target;
				if(this.entity.code == "Departmental-Plan-Completion-Rate" || this.entity.code == "Departmental-Issue-Closure-Rate" || this.entity.code == "Departmental-Compliance-Rate") {
					name = value.departmentName;
					target = newData.find(item => name === item.departmentName);
				}
				if(this.entity.code == "Plan-Completion-Rate" || this.entity.code == "Issue-Closure-Rate" || this.entity.code == "Compliance-Rate") {
					name = value.month;
					target = newData.find(item => name === item.month);
				}
				if(this.entity.code == "Audit-Plan" || this.entity.code == "List-of-issues") {
					name = value.id;
					target = newData.find(item => name === item.id);
				}
				if(this.entity.code == "Level") {
					name = value.levelName;
					target = newData.find(item => name === item.levelName);
				}
				if(this.entity.code == "Issue-Area") {
					name = value.areaName;
					target = newData.find(item => name === item.areaName);
				}
				if(this.entity.code == "Issue-Type") {
					name = value.issueType;
					target = newData.find(item => name === item.issueType);
				}
				if(this.entity.code == "Departmental-hidden-dangers" || this.entity.code == "Key-positions" || this.entity.code == "Hazard-closure-rate" || this.entity.code == "Year-hazard-closure-rate") {
					name = value.id;
					target = newData.find(item => name === item.id);
				}
				this.editingKey = '';
				if (target) {
					if(this.entity.code == "Departmental-Plan-Completion-Rate" || this.entity.code == "Departmental-Issue-Closure-Rate" || this.entity.code == "Departmental-Compliance-Rate") {
						Object.assign(target, this.cacheData.find(item => name === item.departmentName));
					}
					if(this.entity.code == "Plan-Completion-Rate" || this.entity.code == "Issue-Closure-Rate" || this.entity.code == "Compliance-Rate") {
						Object.assign(target, this.cacheData.find(item => name === item.month));
					}
					if(this.entity.code == "Audit-Plan" || this.entity.code == "List-of-issues") {
						Object.assign(target, this.cacheData.find(item => name === item.id));
					}
					if(this.entity.code == "Level") {
						Object.assign(target, this.cacheData.find(item => name === item.levelName));
					}
					if(this.entity.code == "Issue-Area") {
						Object.assign(target, this.cacheData.find(item => name === item.areaName));
						console.log(Object.assign(target, this.cacheData.find(item => name === item.areaName)))
					}
					if(this.entity.code == "Issue-Type") {
						Object.assign(target, this.cacheData.find(item => name === item.issueType));
					}
					if(this.entity.code == "Departmental-hidden-dangers" || this.entity.code == "Key-positions" || this.entity.code == "Hazard-closure-rate" || this.entity.code == "Year-hazard-closure-rate") {
						Object.assign(target, this.cacheData.find(item => name === item.id));
					}
					delete target.editable;
					this.tableData = newData;
				}
			},
			handleChange(value, valueList, column) {
				let newData = [...this.tableData];
				let name;
				let target;
				if(this.entity.code == "Departmental-Plan-Completion-Rate" || this.entity.code == "Departmental-Issue-Closure-Rate" || this.entity.code == "Departmental-Compliance-Rate") {
					name = valueList.departmentName;
					target = newData.find(item => name === item.departmentName);
				}
				if(this.entity.code == "Plan-Completion-Rate" || this.entity.code == "Issue-Closure-Rate" || this.entity.code == "Compliance-Rate") {
					name = valueList.month;
					target = newData.find(item => name === item.month);
				}
				if(this.entity.code == "Audit-Plan" || this.entity.code == "List-of-issues") {
					name = valueList.id;
					target = newData.find(item => name === item.id);
				}
				if(this.entity.code == "Level") {
					name = valueList.levelName;
					target = newData.find(item => name === item.levelName);
				}
				if(this.entity.code == "Issue-Area") {
					name = valueList.areaName;
					target = newData.find(item => name === item.areaName);
				}
				if(this.entity.code == "Issue-Type") {
					name = valueList.issueType;
					target = newData.find(item => name === item.issueType);
				}
				if(this.entity.code == "Departmental-hidden-dangers" || this.entity.code == "Key-positions" || this.entity.code == "Hazard-closure-rate" || this.entity.code == "Year-hazard-closure-rate") {
					name = valueList.id;
					target = newData.find(item => name === item.id);
				}
				if (target) {
					if(column == "noFinsh" || column == "finsh" || column == "finshRate" || column == "noClose" || column == "close" || column == "closeRate" || column == "issueNumber" || column == "complianceRate" || column == "id" || column == "planCount" || column == "value") {
						target[column] = Number(value);
					}else {
						target[column] = value;
					}
					this.tableData = newData;
				}
			},
		    getData() {
		        this.isLoading = true;
		        this.KanBanServiceProxy.getForEdit(
		            this.id
		        ).finally(() => {
		            this.isLoading = false;
		        }).then(res => {
		            this.entity = res;
					if(this.entity.value) {
						this.entity.value = JSON.parse(this.entity.value);
					}
					if(this.entity.paramDesc) {
						this.entity.paramDesc = JSON.parse(this.entity.paramDesc);
					}
					this.parameter = this.entity.paramDesc;
					
					this.columns = [];
					for(let i in this.entity.paramDesc) {
						let data = {};
						data.title = this.l(this.entity.paramDesc[i].paramName);
						// data.title = this.l(this.entity.paramDesc[i].text);
						data.dataIndex = this.entity.paramDesc[i].paramName;
						data.width = 145;
						data.align = 'center';
						data.ellipsis = true;
						data.scopedSlots = {customRender: this.entity.paramDesc[i].paramName};
						this.columns.push(data);
					}
					this.parameterList = this.columns;
					let action = {title: this.l('Action'), dataIndex: "action", width: 100, align: "center", scopedSlots: {customRender: "action"}, fixed: 'right'};
					this.columns.push(action);
					this.tableData = this.entity.value;
					if(!this.tableData) {
						this.tableData = [];
					}
					this.cacheData = this.tableData.map(item => ({ ...item }));
		        })
		    }
		}
	}
</script>

<style>
</style>
