<template>
    <div>
        <!-- 标题 -->
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px" />
                <span>{{(isEdit && !isLook)?l('Parameter_configuration'):l('Create')}}</span>
            </div>
        </div>
        <a-config-provider :locale="zh_CN">
            <a-spin :spinning="isLoading">
                <a-row v-if="!isLoading">
                    <a-col :span="24" >
                    	<a-form-item required :label="l('Code')" :label-col="{ span: 5 }" :wrapper-col="{ span: 16, style:'text-align: left'}">
							 <a-input v-model="entity.code" style="width: 100%;"></a-input>
                    	</a-form-item>
                    </a-col>
                    <a-col :span="24" style="margin-top: -10px;">
						<a-form-item required :label="l('Name')" :label-col="{ span: 5 }" :wrapper-col="{ span: 16, style:'text-align: left'}">
							 <a-input v-model="entity.name" style="width: 100%;"></a-input>
						</a-form-item>
                    </a-col>
                    <a-col :span="24" style="margin-top: -10px;">
						<a-form-item :label="l('Data')" :label-col="{ span: 5 }" :wrapper-col="{ span: 16, style:'text-align: left'}">
							 <a-textarea v-model="entity.value" style="width: 100%;height: 150px"></a-textarea>
						</a-form-item>
                    </a-col>
					<a-col :span="24" style="margin-top: -10px;">
						<a-form-item :label="l('Parameter_structure')" :label-col="{ span: 5 }" :wrapper-col="{ span: 16, style:'text-align: left'}">
							 <a-textarea v-model="entity.paramDesc" style="width: 100%;height: 150px"></a-textarea>
						</a-form-item>
					</a-col>
                    <a-col :span="24" style="margin-top: -10px;">
                    	<a-form-item :label="l('Remark')" :label-col="{ span: 5 }" :wrapper-col="{ span: 16, style:'text-align: left'}">
                    		 <a-textarea v-model="entity.remark" style="width: 100%;"></a-textarea>
                    	</a-form-item>
                    </a-col>
					<a-col :span="24" style="margin-top: -10px;">
						<a-form-item :label="l('Whether_to_connect_data')" :label-col="{ span: 5 }" :wrapper-col="{ span: 16, style:'text-align: left'}">
							<a-switch :checked-children="l('Yes')" :un-checked-children="l('No')" v-model="entity.isDockingData"/>
						</a-form-item>
					</a-col>
                </a-row>
                <br>
            </a-spin>
        </a-config-provider>
        <!-- 按钮 -->
        <div class="modal-footer" v-if="!isLook">
            <a-button @click="close">
                {{l('Cancel')}}
            </a-button>
            <a-button :type="'primary'" @click="save">
                {{l('Save')}}
            </a-button>
        </div>
    </div>
</template>

<script>
	import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
	import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
	import { KanBanServiceProxy } from '../../../../shared/service-proxies';
	
	let _this;
	export default {
		name: 'createOredit',
		mixins: [ModalComponentBase],
		components: {
		    
		},
		data() {
		    return {
		        zh_CN,
		        isEdit: false,
		        isLook: false,
		        id: undefined,
		        isLoading: false,//加载中
				entity: {}
		    }
		},
		created() {
		    _this = this;
		    this.fullData(); // 模态框必须,填充数据到data字段
			this.KanBanServiceProxy = new KanBanServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
		    this.getData()
		},
		methods: {
		    save() {
		        if (!this.entity.code) {
		            abp.message.info('编码不可为空');
		            return;
		        }
		        if (!this.entity.name) {
		            abp.message.info('名称不可为空');
		            return;
		        }
				// var issueProgress = {issueProgress:this.entity};
				this.KanBanServiceProxy.createOrUpdate(
				    this.entity
				).finally(() => {
					this.close()
				}).then(res => {
				    this.success(true);
				})
		    },
		    getData() {
		        this.isLoading = true;
		        this.KanBanServiceProxy.getForEdit(
		            this.id
		        ).finally(() => {
		            this.isLoading = false;
		        }).then(res => {
		            this.entity = res;
		        })
		    }
		}
	}
</script>

<style>
</style>
